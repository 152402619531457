import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Play = () => {
	return (
		<AccessibleIcon.Root label={'Play'}>
			<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M12 10L22 16L12 22V10Z' fill='#080593' />
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Play;
