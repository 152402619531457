import { CutPaper, Heading, Img } from '@components';
import createCutPaperPath from '@utils/create-cut-paper';
import styles from './TopFrameHeading.module.scss';
import type { Image } from '@ts/shopify-storefront-api';

type TopFrameHeadingProps = {
	title: string;
	logo?: Image;
	hasButton?: boolean;
	isLeftAlign?: boolean;
	isRightAlign?: boolean;
	showCutPaper?: boolean;
};

const TopFrameHeading = ({
	title,
	logo,
	isLeftAlign = false,
	isRightAlign = false,
	showCutPaper = false,
}: TopFrameHeadingProps) => {
	return (
		<div className={styles.headingContainer}>
			<div className={isLeftAlign ? styles.leftAlignContainer : styles.container}>
				{
					logo ? (
						<div className={isRightAlign ? styles.rightAlignLogo : undefined}>
							<Img
								className={styles.logo ?? undefined}
								src={logo.url}
								alt={logo.altText}
								width={logo.width}
								height={logo.height}
							/>
						</div>
					) : showCutPaper ? (
						<CutPaper padding='16px' cutPaperPath={createCutPaperPath('pair')}>
							<Heading tag='h1' style={{ textAlign: 'center' }}>
								{title}
							</Heading>
						</CutPaper>
					) : null // If there's no logo to display, we want the title to render as part of a `ValuePropCard` component
				}
			</div>
		</div>
	);
};

export default TopFrameHeading;
