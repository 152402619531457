import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Pause = () => {
	return (
		<AccessibleIcon.Root label={'Pause'}>
			<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect x='11' y='9' width='3' height='14' fill='#080593' />
				<rect x='18' y='9' width='3' height='14' fill='#080593' />
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Pause;
